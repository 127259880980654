import React from "react"
import '../styles/global.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/Title/"
import GoogleMap from '../components/map'

import CellPhone from "../icons/cell_phone.svg"
import Telephone from "../icons/telephone.svg"
import Email from "../icons/email.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Biuro Rachunkowe Jolanta Saska" />

    <section className="section has-bg-color">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="content">
              <h1 className='title is-1 is-spaced'><Title text="Polityka prywatności" /></h1>
              <ol>
                <li>Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.</li>
                <li>Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
                <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu Biuro Rachunkowe Jolanta Saska z siedzibą przy ul. Vetulaniego 1A, 31-227 Kraków </li>
                <li>
                  Pliki cookies wykorzystywane są w celu:
                  <ul className="no-style">
                    <li>a) dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;</li>
                    <li>b) tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</li>
                    <li>c) utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</li>
                  </ul>
                </li>
                <li>W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne”  (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
                <li>
                  W ramach Serwisu stosowane są następujące rodzaje plików cookies:
                  <ul className="no-style">
                    <li>a)  „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;</li>
                    <li>b)  pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;</li>
                    <li>c)  „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;</li>
                    <li>d)  „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;</li>
                    <li>e)  „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.</li>
                  </ul>
                </li>
                <li>W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</li>
                <li>Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</li>
                <li>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" className="section contact-section">
      <div className="contact-section__half"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="contact-section__content has-text-white">
              <h3 className='title is-1 is-spaced has-text-white'><Title text="Kontakt" /></h3>
              <div className="has-text-white contact-info">
                Jolanta Saska - Biuro Rachunkowe <br />
                ul. Vetulaniego 1A / pok. 210 <br />
                31-227 Kraków <br />
                NIP: 945-13-43-698 <br/>
              </div>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div className="contact-item">
                    <CellPhone width="32px"  height="32px" />
                    <a href="tel:+48502276292">502 276 292</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Telephone width="32px"  height="32px" />
                    <a href="tel:+48124202130">12 420 21 30</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Email width="32px"  height="32px" />
                    <a href="mailto:jolanta.saska@gmail.com">jolanta.saska@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <div className="map-container">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default IndexPage
